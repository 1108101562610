// src/component/sendOTP.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "./api/axios"; // import axiosInstance

function SendOTP() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleGenerateOTP = async () => {
    try {
      // Request to the backend to generate and send the OTP
      const response = await axiosInstance.post("/generate-otp", { email });

      if (response.status === 200) {
        setMessage("Temporary Pass Key was successfully sent.");
        navigate("/verify-otp", { state: { userEmail: email } });
      } else {
        setMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
      setMessage("Failed to send OTP. Please try again.");
    }
  };

  return (
    <div className="App">
      <header className="centered-text">
        <br />
        <h3 className="OTPTitles">Forgot Password?</h3>
        <div>
          <side>
            Enter your registered email to receive a temporary Pass Key (expires
            in 3 minutes). <br />
            Upon submission, you'll be redirected to enter the Pass Key and
            reset your password.
          </side>
        </div>
        <br />
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <button onClick={handleGenerateOTP}>Send Temporary Code</button>
        {message && <p>{message}</p>}
      </header>
    </div>
  );
}

export default SendOTP;
