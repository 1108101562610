import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "./api/axios"; // import axiosInstance

function VerifyOTP() {
  const location = useLocation();
  const { userEmail } = location.state;
  const [email, setEmail] = useState(userEmail);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(180);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setDisabled(true);
    }
  }, [timeLeft]);

  const handleSubmit = async () => {
    if (!disabled) {
      console.log("Submitting OTP verification:", { email, otp }); // Log inputs
      try {
        const response = await axiosInstance.post("/verify-otp", {
          email,
          otp,
        });
        console.log("OTP verification response:", response.data); // Log response
        setMessage("OTP verified successfully!");
        navigate("/PasswordReset", { state: { email } });
      } catch (error) {
        console.error("Error verifying OTP:", error); // Log the error for debugging
        if (error.response) {
          // Server responded with a status other than 2xx
          setMessage(
            error.response.data || "Incorrect OTP or email. Please try again."
          );
        } else if (error.request) {
          // Request was made but no response received
          setMessage("No response received from server");
        } else {
          // Something happened in setting up the request
          setMessage("Error in request setup");
        }
      }
    } else {
      setMessage("Time is up. Please request a new OTP.");
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className="centered-text">
      <header className="OTPTitles">
        <br></br>
        <h2>Verify One Time Password</h2>
        {timeLeft > 0 ? (
          <p>Time left: {formatTime(timeLeft)}</p>
        ) : (
          <p>Time is up!</p>
        )}
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={timeLeft === 0}
        />
        <br></br>
        <br></br>

        <input
          type="text"
          placeholder="Enter Temporary Key"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          disabled={timeLeft === 0}
        />
        <br></br>
        <br></br>

        <button onClick={handleSubmit} disabled={timeLeft === 0}>
          Verify to Reset Password
        </button>
        {message && <p>{message}</p>}
      </header>
    </div>
  );
}

export default VerifyOTP;
