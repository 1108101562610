import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  faCheck,
  faTimes,
  faInfoCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "./api/axios"; // import axiosInstance
import Notification from "../utils/notification";
import emailjs from "emailjs-com";
import { EMAILJS_USER_ID } from "../config/emailJSConfig";
import { EMAILJS_SERVICE_ID } from "../config/emailJSConfig";
import { EMAILJS_NEWACCOUNT_TEMPLATE_ID } from "../config/emailJSConfig";
import { useNavigate } from "react-router-dom";

const USER_REGEX = /^(?!.* {2,})[A-Za-z0-9][A-Za-z0-9 _-]{2,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const MCNUMBER_REGEX = /^[0-9]{3,7}$/;
const REGISTER_URL = "https://thebroker411.com:8443/api/register";

const Register = () => {
  const [showNotification, setShowNotification] = useState(true);
  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [mcnumber, setMcnumber] = useState("");
  const [validMcnumber, setValidMcnumber] = useState(false);
  const [mcnumberFocus, setMcnumberFocus] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [validUserEmail, setValidUserEmail] = useState(false);
  const [userEmailFocus, setUserEmailFocus] = useState(false);
  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState("");
  const [validConfirmPwd, setValidConfirmPwd] = useState(false);
  const [confirmPwdFocus, setConfirmPwdFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);

  const userRef = useRef();
  const errRef = useRef();
  const mcnumberRef = useRef();
  const emailRef = useRef();
  const navigate = useNavigate();

  const togglePwdVisibility = () => {
    setShowPwd(!showPwd);
  };

  const toggleConfirmPwdVisibility = () => {
    setShowConfirmPwd(!showConfirmPwd);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    mcnumberRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidMcnumber(MCNUMBER_REGEX.test(mcnumber));
  }, [mcnumber]);

  useEffect(() => {
    setValidUserEmail(EMAIL_REGEX.test(userEmail));
  }, [userEmail]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd]);

  useEffect(() => {
    setValidConfirmPwd(confirmPwd === pwd);
  }, [confirmPwd, pwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, mcnumber, userEmail, pwd, confirmPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(userEmail);
    const v4 = MCNUMBER_REGEX.test(mcnumber);

    if (!v1 || !v2 || !v3 || !v4) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/register",
        JSON.stringify({ user, mcnumber, userEmail, pwd })
      );
      console.log(response?.data);
      console.log(response?.accessToken);
      console.log(JSON.stringify(response));
      setSuccess(true);
      setUser("");
      setMcnumber("");
      setUserEmail("");
      setPwd("");
      setConfirmPwd("");

      // Send email using EmailJS
      var templateParams = {
        to_name: user,
        to_email: userEmail,
      };
      emailjs
        .send(
          EMAILJS_SERVICE_ID,
          EMAILJS_NEWACCOUNT_TEMPLATE_ID,
          templateParams,
          EMAILJS_USER_ID
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            window.location.href = "https://buy.stripe.com/9AQ6oSal70oIcYE6or";
          },

          (error) => {
            console.log("FAILED...", error);
          }
        );
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      console.error("Registration Failed:", err);
      errRef.current.focus();
    }
  };

  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      {/* <div className="register-page">
        {showNotification && (
          <Notification
            message={Notification}
            onClose={handleNotificationClose}
          />
        )}
      </div> */}

      <br />
      <h1 className="pageTitles">Register</h1>
      {success ? (
        <section className="registersection">
          <h1>Success!</h1>
          <p>
            <a href="#">Sign In</a>
          </p>
        </section>
      ) : (
        <section className="registersection">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form onSubmit={handleSubmit}>
            {/* Username */}
            <label htmlFor="username">
              Carrier Name:
              <FontAwesomeIcon
                icon={faCheck}
                className={validName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validName || !user ? "hide" : "invalid"}
              />
            </label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            />
            <p
              id="uidnote"
              className={
                userFocus && user && !validName ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              4 to 24 characters.
              <br />
              Must begin with a letter.
              <br />
              Letters, numbers, underscores, hyphens allowed.
            </p>
            {/* Email Address */}
            <label htmlFor="userEmail">
              Email Address:
              <FontAwesomeIcon
                icon={faCheck}
                className={validUserEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validUserEmail || !userEmail ? "hide" : "invalid"}
              />
            </label>
            <input
              type="email"
              id="userEmail"
              ref={emailRef}
              autoComplete="off"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              required
              aria-invalid={validUserEmail ? "false" : "true"}
              aria-describedby="useremailnote"
              onFocus={() => setUserEmailFocus(true)}
              onBlur={() => setUserEmailFocus(false)}
            />
            <p
              id="useremailnote"
              className={
                userEmailFocus && userEmail && !validUserEmail
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Valid email address
              <br />
            </p>

            {/* MC Number */}
            <label htmlFor="mcnumber">
              MC Number:
              <FontAwesomeIcon
                icon={faCheck}
                className={validMcnumber && mcnumber ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMcnumber || !mcnumber ? "hide" : "invalid"}
              />
            </label>
            <input
              type="number"
              id="mcnumber"
              ref={mcnumberRef}
              autoComplete="off"
              onChange={(e) => setMcnumber(e.target.value)}
              value={mcnumber}
              required
              aria-invalid={validMcnumber ? "false" : "true"}
              aria-describedby="mcnumbernote"
              onFocus={() => setMcnumberFocus(true)}
              onBlur={() => setMcnumberFocus(false)}
              className="number-to-text"
            />
            <p
              id="mcnumbernote"
              className={
                mcnumberFocus && mcnumber && !validMcnumber
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              MC Number
              <br />
              Please don't include the "MC" prefix.
              <br />
              No hyphens or underscores.
            </p>

            {/* Password */}
            <label htmlFor="password">
              Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                flexGrow: 1,
                paddingBottom: "1rem",
                fontFamily: "Nunito, sans-serif",
                fontSize: "22px",
                padding: "0.25rem",
                borderRadius: "0.5rem",
                appearance: "none",
                marginBottom: "-9%",
              }}
            >
              <input
                type={showPwd ? "text" : "password"}
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <i
                onClick={togglePwdVisibility}
                style={{
                  position: "relative",
                  right: "-340px",
                  top: "-25%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={showPwd ? faEye : faEyeSlash} />
              </i>
            </div>
            <p
              id="pwdnote"
              className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number, and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span>
            </p>

            {/* Confirm Password */}
            <label htmlFor="confirm_pwd">
              Confirm Password:
              <FontAwesomeIcon
                icon={faCheck}
                className={validConfirmPwd && confirmPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validConfirmPwd || !confirmPwd ? "hide" : "invalid"}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                flexGrow: 1,
                paddingBottom: "1rem",
                fontFamily: "Nunito, sans-serif",
                fontSize: "22px",
                padding: "0.25rem",
                borderRadius: "0.5rem",
                appearance: "none",
                marginTop: "0",
              }}
            >
              <input
                type={showConfirmPwd ? "text" : "password"}
                id="confirm_pwd"
                onChange={(e) => setConfirmPwd(e.target.value)}
                value={confirmPwd}
                required
                aria-invalid={validConfirmPwd ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setConfirmPwdFocus(true)}
                onBlur={() => setConfirmPwdFocus(false)}
              />
              <i
                onClick={toggleConfirmPwdVisibility}
                style={{
                  position: "relative",
                  right: "-340px",
                  top: "-25%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={showConfirmPwd ? faEye : faEyeSlash} />
              </i>
            </div>
            <p
              id="confirmnote"
              className={
                confirmPwdFocus && !validConfirmPwd
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the first password input field.
            </p>
            <br />
            <button disabled={!validName || !validPwd || !validConfirmPwd}>
              Sign Up
            </button>

            <p>
              {" "}
              <br></br>
              By signing up, you agree to our{" "}
              <Link to="/terms-and-conditions">
                Terms & Conditions
              </Link> and <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </form>

          <p>
            Already registered?
            <br />
            <span className="line">
              {/* put router link here */}
              <a href="/Login">Sign In</a>
            </span>
          </p>
        </section>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};
export default Register;
